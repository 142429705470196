import kotlinx.browser.document
import org.w3c.dom.Attr
import org.w3c.dom.asList
import org.w3c.dom.get

private val translations = buildTranslation {
    "child-info"("Child's Information", "Информация о Ребёнке", "Інформація Про Дитину")
    "child-name"("Name:", "Имя:", "Iм'я:")
    "child-last-name"("Last name:", "Фамилия:", "Прізвище:")
    "child-age"("Age:", "Возраст:", "Вік:")
    "child-birthday"("Birthday:", "День рождения:", "День народження:")
    "address"("Address:", "Адрес:", "Адреса:")
    "visited-before"("Has the child visited the school last year?",
        "Посещал ли ребёнок школу в прошлом году?", "Чи відвідувала дитина школу минулого року?")
    "last-grade"("What grade has the child has just finished?",
        "Какой класс закончил ребёнок в этом году?", "Який клас закінчила?")
    "misc-info"("What should we know about your child?",
        "Что нам надо знать о вашем ребёнке?", "Що ми маємо знати про вашу дитину?")

    "add-child"("Add Child", "Добавить Ребёнка", "Додати Дитину")

    "parent-info"("Parents' Information", "Информация о Родителях", "Інформація Про Батьків")
    "mother-name"("Mother's name:", "Имя матери:", "Ім'я матері:")
    "father-name"("Father's name:", "Имя отца:", "Ім'я батька:")
    "mother-tel"("Mother's telephone:", "Телефон матери:", "Телефон матері:")
    "father-tel"("Father's telephone:", "Телефон отца:", "Телефон батька:")
    "email"("Email:", "Электронная почта:", "Електронна пошта:")

    "pricing"("""
        <h2>Pricing</h2>
        <p>For members of the church, the church will cover all costs. For non-members, the price will be $40 per 
        child per month. It needs to be paid the first class every month.</p>
    """.trimIndent(), """
        <h2>Цена</h2>
        <p>Для членов церкви - церковь оплачивает обучение за каждого ребёнка. Для не членов - цена 40 долларов за 
        ребенка в месяц. Оплата в первое занятие месяца.</p>
    """.trimIndent(), """
        <h2>Ціна</h2>
        <p>Для членів церкви - церква оплачує за кожну дитину. Для не членів - ціна 40 доларів за дитину на місяць. 
        Щомісяця потрібно платити на перше заняття.</p>
    """.trimIndent())

    "rules"("""
        <h2>Rules</h2>
        <ol>
            <li>Classes are held on Wednesday from 19:00 to 20:45.</li>
            <li>The age of incoming children is 5-6 years old</li>
            <li>During lessons, the student must have all the required textbooks and supplies.</li>
            <li>Students must dress in accordance with the rules of the school and the church.</li>
            <li>Parents should bring their child to class before class starts and pick them up after class ends.</li>
            <li>Parents are required to be on duty at the school during school hours on a rotational basis.</li>
            <li>Tuition fee is calculated for 1 month, <strong>regardless of the number of classes and visits.</strong></li>
        </ol>
    """.trimIndent(), """
        <h2>Правила</h2>
        <ol>
            <li>Занятия проходят в среду с 19:00 до 20:45.</li>
            <li>Возраст поступающих детей - 5-6 лет</li>
            <li>На уроках ученик должен иметь все необходимые учебники и принадлежности.</li>
            <li>Одежда учеников должна соответствовать правилам школы и церкви.</li>
            <li>Родители должны привести ребёнка в класс перед началом занятий и забрать из класса после их окончания.</li>
            <li>Родители должны дежурить в школе во время занятий в порядке определённой очереди.</li>
            <li>Оплата за обучение рассчитана за 1 месяц обучения, <strong>независимо от количества занятий и 
            посещений.</strong></li>
        </ol>
    """.trimIndent(), """
        <h2>Правила</h2>
        <ol>
            <li>Заняття відбуваються у п'ятницю з 19:00 до 20:45.</li>
            <li>Вік дітей з 7 років</li>
            <li>На уроках учень повинен мати всі необхідні підручники та приладдя.</li>
            <li>Одяг учнів повинен відповідати правилам школи та церкви.</li>
            <li>Батьки повинні привести дитину до класу перед початком занять і забрати з класу після їх закінчення.</li>
            <li>Батьки повинні чергувати у школі під час занять у порядку певної черги.</li>
            <li>Оплата за навчання розрахована за 1 місяць навчання, <strong>незалежно від кількості занять та 
            відвідувань.</strong></li>
        </ol>
    """.trimIndent())
    "start"("Classes start on September 12 at 19:00",
        "Начало занятий 12 Сентября в 19:00", "Початок занять 12 Вересня о 19:00")
    "liability"("""
        <h2>Release of Liability Form</h2>
        <p>I hereby agree to be responsible for the conduct and actions of my child/children and to release Wayside 
        Baptist Church from any claims and demands that may occur during participation in the School of Russian/Ukrainian 
        language classes held on Wayside Baptist Church property every Wednesday/Friday from 19:00 to 20:45. 
        Furthermore, I agree to release Wayside Baptist Church and its staff and will hold them harmless from any 
        liabilities which arise from incidents or accidents involving my child/children and myself while on Wayside 
        Baptist Church premises, to the extent allowed by law.</p>
        <p>This release form will be valid for the specified child/children for the 2022-2023 school year.</p>
    """.trimIndent(), """
        <h2>Форма об освобождении от ответственности</h2>
        <p>Я согласен с тем, что несу ответственность за поведение и действия моего ребенка/детей во время проведения 
        занятий в Школе Русского/Украинского Языков, которые проводятся в здании Церкви "Wayside Baptist Church" 
        каждую среду/пятницу с 19:00 до 20:45 и освобождаю Церковь "Wayside Baptist Church" и администрацию Школы 
        Русского/Украинского Языков от любой ответственности, в результате инцидентов, связанных с моим ребенком/детьми 
        во время нахождения в здании Церкви "Wayside Baptist Church".</p>
        <p>Эта форма действительна в течение 2022-2023 учебного года.</p>
    """.trimIndent(), """
        <h2>Форма про звільнення від відповідальності</h2>
        <p>Я згоден з тим, що несу відповідальність за поведінку та дії моєї дитини/дітей під час 
        проведення занять у Школі Руської/Української Мов, які проводяться в будівлі Церкви "Wayside Baptist Church" 
        щосереди/п'ятниці з 19:00 до 20:45 і звільняю Церкву "Wayside Baptist Church" та адміністрацію Школи 
        Руської/Української Мов від будь-якої відповідальності, внаслідок інцидентів, пов'язаних з моєю дитиною/дітьми 
        під час перебування у будівлі Церкви "Wayside Baptist Church".</p>
        <p>Ця форма дійсна протягом 2022-2023 навчального року.</p>
    """.trimIndent())
    "signature"("Signature", "Роспись", "Розпис")
    "clear"("Clear", "Очистить", "Очистити")
    "signature-required"("Please provide a signature", "Пожалуйста, надо роспись", "Будь ласка, надайте розпис")
    "enter"("Submit", "Отправить", "Відправити")
    "submitted"("Submitted", "Отправлено", "Відправлено")
    "required-field-missing"("Required field missing", "Отсутствует обязательная информацыя", "Відсутня обов'язкова інформація")
}

const val TRANSLATABLE = "translatable"

private class TranslationContext {

    val map = mutableMapOf<String, Map<Language, String>>()

    operator fun String.invoke(en: String, ru: String, ua: String = ru) {
        map[this] = mapOf(
            Language.ENGLISH to en, Language.RUSSIAN to ru, Language.UKRAINIAN to ua
        )
    }
}

private fun buildTranslation(f: TranslationContext.() -> Unit): Map<String, Map<Language, String>> {
    val ctx = TranslationContext()
    ctx.f()
    return ctx.map
}

enum class Language(val abbr: String) {
    ENGLISH("en"), RUSSIAN("ru"), UKRAINIAN("ua");

    companion object {
        fun getByAbbr(abbr: String): Language =
            values().find { it.abbr == abbr } ?: throw IllegalArgumentException("Not a language: '$abbr'")
    }
}

fun getTranslation(key: String, language: Language): String =
    translations[key]?.get(language) ?: throw IllegalArgumentException("Not a translation: '$key'")

var currentLanguage: Language = Language.ENGLISH

fun changeLanguage(language: Language) {
    val translatable = document.getElementsByClassName(TRANSLATABLE).asList()
    for (obj in translatable) {
        val id = obj.classList.asList().toMutableList()
        id.remove(TRANSLATABLE)

        obj.innerHTML = getTranslation(id.first(), language)
    }
    console.log("Language changed to $language")
    currentLanguage = language
}