import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.Element
import org.w3c.dom.HTMLFormElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLLabelElement
import org.w3c.dom.asList
import org.w3c.dom.events.Event
import org.w3c.dom.events.EventTarget
import org.w3c.dom.get
import org.w3c.fetch.RequestInit
import kotlin.js.Date

fun main() {
    window.onload = { onLoad() }
}

@Suppress("UnsafeCastFromDynamic")
fun onLoad() {
    val button = document.querySelector("#add-child")!!
    button.addEventListener("click") {
        it.preventDefault()
        val section = document.querySelector("#child-info-sec")!!.cloneNode(true) as Element
        section.removeAttribute("id")
        section.removeAttribute("hidden")
        for (label in section.querySelectorAll("label").asList().filterIsInstance<Element>()) {
            label.setAttribute("for", label.getAttribute("for") + "1")
        }
        for (input in section.querySelectorAll("input").asList().filterIsInstance<Element>()) {
            input.setAttribute("id", input.getAttribute("id") + "1")
        }
        button.insertAdjacentElement("beforebegin", section)
    }
    button.dispatchEvent(Event("click"))

    for (elm in document.querySelectorAll("#lang-sel > button").asList().filterIsInstance<Element>()) {
        elm.addEventListener("click") {
            it.preventDefault()
            changeLanguage(Language.getByAbbr(elm.id))
        }
    }
    changeLanguage(Language.ENGLISH)

    val pad = js("new SignaturePad(document.querySelector('canvas'))")
    pad.on()

    document.querySelector(".clear")!!.addEventListener("click") {
        it.preventDefault()
        pad.clear()
    }

    document.querySelector(".enter")!!.addEventListener("click") {
        it.preventDefault()
        if (pad.isEmpty() as Boolean) {
            window.alert(getTranslation("signature-required", currentLanguage))
        } else {
            val data = pad.toDataURL() as String
            val sb = StringBuilder()
            sb.appendLine("<br>")
            val datetime = Date().toLocaleString("en-US")
            sb.appendLine("<h2>$datetime</h2>")
            document.querySelectorAll("input").asList().filterIsInstance<HTMLInputElement>().filter { e ->
                !(e.parentElement?.parentElement?.hasAttribute("hidden") ?: true)
            }.forEach { e ->
                val id = (e.labels[0] as HTMLLabelElement).classList.asList().toMutableList()
                id.remove(TRANSLATABLE)
                val value = if (e.type == "checkbox") {
                    if (e.checked) "Да" else "Нет"
                } else {
                    if (e.value.isEmpty() && e.hasAttribute("required")) {
                        window.alert(getTranslation("required-field-missing", currentLanguage) + ": "
                                + getTranslation(id.first(), currentLanguage).replace(":", ""))
                        return@addEventListener
                    } else {
                        e.value
                    }
                }
                sb.appendLine("<p>${getTranslation(id.first(), Language.RUSSIAN)} ${value}</p>")
            }
            window.fetch("https://rswsbc.seggy.workers.dev/submit", RequestInit(
                method = "POST",
                body = "$sb<h2>Роспись:</h2><img style=\"border: 1px solid black\" src=\"$data\" />"
            ))
            window.alert(getTranslation("submitted", currentLanguage))
        }
    }
}

private fun EventTarget.addEventListener(event: String, callback: (Event) -> Unit) {
    addEventListener(event, callback)
}